import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Slide = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
  justify-content: center;
  max-width: 575px;
`;

export const Content = styled(Text.H3Semibold)`
  color: ${colours.neutralWarm[800]};
  text-align: center;

  @media (max-width: ${device.tablet}) {
    font-size: 19px;
    line-height: 23px;
  }
`;
