import React, { useEffect, useState } from 'react';

// components
import ButtonBackAndProgressIndicator from 'components/pageOnboarding/ButtonBackAndProgressIndicator';
import ButtonContinue from 'components/pageOnboarding/ButtonContinue';
import ButtonsDisclaimer from 'components/pageOnboarding/ButtonsDisclaimer';
import ConfidentialityCard from 'components/pageOnboarding/ConfidentialityCard';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import SlideGeneral from 'components/pageOnboarding/SlideGeneral';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import SlideWelcome from './SlideWelcome';

export default function Onboarding() {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [showWelcome, setShowWelcome] = useState<boolean>(true);

  const TOTAL_SLIDES = 5;

  const nextSlide = () => setCurrentSlide((prev) => prev + 1);
  const prevSlide = () => setCurrentSlide((prev) => prev - 1);

  useEffect(() => {
    setTimeout(() => {
      setShowWelcome(false);
    }, 5900);
  }, []);

  if (showWelcome)
    return (
      <ErrorBoundary>
        <Page.Page>
          <Page.InnerPage>
            <SlideWelcome />
          </Page.InnerPage>
        </Page.Page>
      </ErrorBoundary>
    );

  return (
    <ErrorBoundary>
      <Page.Page>
        <Page.InnerPage>
          <ButtonBackAndProgressIndicator
            currentSlide={currentSlide}
            prevSlide={prevSlide}
            totalSlides={TOTAL_SLIDES}
          />

          {currentSlide < TOTAL_SLIDES - 1 ? (
            <SlideGeneral currentSlide={currentSlide} />
          ) : (
            <ConfidentialityCard />
          )}

          {currentSlide < TOTAL_SLIDES - 1 ? (
            <ButtonContinue currentSlide={currentSlide} nextSlide={nextSlide} />
          ) : (
            <ButtonsDisclaimer />
          )}
        </Page.InnerPage>
      </Page.Page>
    </ErrorBoundary>
  );
}
