import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// hooks
import useApi from 'hooks/useApi';
import { useUser } from 'contexts/UserContext';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

function ButtonsDisclaimer() {
  const { t } = useTranslation('pageOnboarding');
  const posthog = usePostHog();
  const { callApi } = useApi();
  const { data: user } = useUser();

  const onDisgreeClick = () => {
    localStorage.removeItem('authorization');
    posthog?.capture('user_declined_privacy_agreement');
    window.location.reload();
  };

  const onAgreeClick = () => {
    callApi('/users/identity/', {
      method: 'put',
      data: {
        nickname: user.first_name,
        pronouns: ['-'],
      },
    });

    callApi('/members/communication-preferences/ ', {
      method: 'put',
      data: {
        communication_preference: 'phone_call',
        feedback_mode: 'virtual',
        feedback_timing: 'immediate',
      },
    });

    posthog?.capture('user_accepted_privacy_agreement');
    window.location.reload();
  };

  return (
    <Buttons.SideBySide>
      <Buttons.Tertiary
        centerText
        fullWidth
        primarySizing
        onClick={onDisgreeClick}
      >
        {t('Buttons.decline')}
      </Buttons.Tertiary>

      <Buttons.Secondary
        centerText
        fullWidth
        primarySizing
        aria-label={t('Buttons.accept')}
        onClick={onAgreeClick}
      >
        {t('Buttons.accept')}
      </Buttons.Secondary>
    </Buttons.SideBySide>
  );
}

export default ButtonsDisclaimer;
