import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { General } from 'untitledui-js';
import routesConfig from 'constants/routesConfig.json';
import * as Buttons from 'components/_styles/Buttons.style';

interface CloseTourProps {
  onClick: () => void;
  currentStep: number;
  numSteps: number;
  tourType: string;
}

const CloseTour: React.FC<CloseTourProps> = ({
  onClick,
  currentStep,
  numSteps,
  tourType,
}) => {
  const { t } = useTranslation('productTour');
  const history = useHistory();

  const closeTour = () => {
    onClick();

    // Remove the 'tour' parameter from the URL
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete('tour');
    history.replace({ search: searchParams.toString() });
  };

  const closeTourBeforeEnd = () => {
    closeTour();

    if (tourType === 'demo') history.push({ search: '' });
  };

  const closeTourAtEnd = () => {
    closeTour();

    history.push(
      tourType === 'demo' ? { search: '' } : `${routesConfig.CONVO_PAGE}?reset`
    );
  };

  if (currentStep !== numSteps - 1)
    return (
      <General.XClose
        onClick={closeTourBeforeEnd}
        size="15"
        style={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
      />
    );
  return (
    <>
      <Buttons.Primary
        centerText
        onClick={closeTourAtEnd}
        style={{
          position: 'absolute',
          bottom: '15px',
          left: '20px',
          right: '20px',
          marginTop: '10px',
          marginBottom: '50px',
          width: 'unset',
        }}
      >
        {tourType === 'demo'
          ? t('demoTour.Get started')
          : t('onboardingTour.slide8.button')}
      </Buttons.Primary>
      <div></div>
    </>
  );
};

export default CloseTour;
