import React, { useCallback, useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import routesConfig from 'constants/routesConfig.json';

// assets
import { General, Layout, MediaDevices, Maps } from 'untitledui-js';

// components
import DemoMessage from 'components/sidePanel/DemoMessage';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import LanguageToggle from 'components/sidePanel/LanguageToggle';
import Logout from 'components/sidePanel/Logout';
import PanelButton from 'components/sidePanel/PanelButton';
import PanelNav from 'components/sidePanel/PanelNav';
import TopCard from 'components/sidePanel/TopCard';

// context
import { useConvoHistory } from 'contexts/ConvoHistoryContext';
import { useUser } from 'contexts/UserContext';
import useWindowSize from 'hooks/useWindowResize';

// styles
import * as Styled from 'components/sidePanel/_styles/SidePanel.style';
import * as Text from 'components/_styles/Text.style';

interface SidePanelProps {
  activeTab?: string;
  getUser: (path: string) => void;
}

interface Conversation {
  id: string | number;
  resolution_status: string;
}

interface UserData {
  is_team_lead?: boolean;
  is_demo?: boolean;
  is_internal?: boolean;
}

export default function SidePanel({
  getUser,
}: SidePanelProps): React.ReactElement {
  const { t } = useTranslation('sidePanel');
  const { data: conversations, getConversations } = useConvoHistory();
  const { data: user } = useUser() as { data: UserData };
  const posthog = usePostHog();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  const windowSize = useWindowSize();
  const windowWidth = windowSize[0];

  const searchParams = new URLSearchParams(location.search);
  const showSidePanel = searchParams.get('showpanel');

  const [pageSelection, setPageSelection] = useState('');
  const [sidePanelOpen, setSidePanelOpen] = useState(showSidePanel === 'true');

  useEffect(() => {
    if (windowWidth > 1025) setSidePanelOpen(true);
  }, [windowWidth]);

  useEffect(() => {
    if (pathname === routesConfig.ABOUT_PAGE) setPageSelection('about');
    if (pathname === routesConfig.INSIGHTS_PAGE) setPageSelection('insights');
    if (pathname.includes(`${routesConfig.DOCUMENT_PAGE}/`))
      setPageSelection('document');
    if (pathname.includes(`${routesConfig.CONVO_PAGE}/`))
      setPageSelection('conversation');
    if (pathname === routesConfig.JOURNEY_ID_PAGE) setPageSelection('journeys');
    if (pathname === routesConfig.JOURNEY_LIST_PAGE)
      setPageSelection('journeys');
    if (pathname === routesConfig.SLIDESHOW_PAGE) setPageSelection('journeys');
    if (pathname === routesConfig.DEMO_SETTINGS_PAGE)
      setPageSelection('demo settings');
    if (showSidePanel === 'false') setSidePanelOpen(false);
    if (showSidePanel === 'true') setSidePanelOpen(true);
  }, [showSidePanel, pathname]);

  const setPage = useCallback(
    (page: string) => {
      setPageSelection(page);
      posthog?.capture(`side_panel_click_${page}`);
      setSidePanelOpen(false);
    },
    [posthog]
  );

  const allConversations = conversations || [];

  const inProgress = allConversations.filter(
    (chat: Conversation) => chat.resolution_status === 'active'
  );

  const allConversationsLength = allConversations?.length || 0;
  const inProgressLength = inProgress?.length || 0;

  const openChat = useCallback(() => {
    history.push(`${routesConfig.CONVO_PAGE}`);
    setPage('conversation');
  }, [history, setPage]);

  const openAbout = useCallback(() => {
    history.push(routesConfig.ABOUT_PAGE);
    setPage('about');
  }, [history, setPage]);

  const openHistory = useCallback(() => {
    getConversations();
    history.push(`${routesConfig.ALL_CONVOS_PAGE}`);
    setPage('all conversations');
  }, [history, setPage, getConversations]);

  const openJourneys = useCallback(() => {
    if (user.is_demo && !user.is_internal) return;
    history.push(`${routesConfig.JOURNEY_LIST_PAGE}`);
    setPage('journeys');
  }, [history, setPage, user]);

  const openInsights = useCallback(() => {
    history.push(`${routesConfig.INSIGHTS_PAGE}`);
    setPage('insights');
  }, [history, setPage]);

  const openDemoSettings = useCallback(() => {
    history.push(`${routesConfig.DEMO_SETTINGS_PAGE}`);
    setPage('demo settings');
  }, [history, setPage]);

  return (
    <ErrorBoundary>
      <Styled.OpenMenu onClick={() => setSidePanelOpen(true)}>
        <Layout.LayoutLeft size={'20'} />
        <Text.P2Semibold>{t('Menu')}</Text.P2Semibold>
      </Styled.OpenMenu>

      <Styled.Container
        sidePanelOpen={sidePanelOpen}
        onClick={() => setSidePanelOpen(false)}
      >
        <Styled.InnerContainer onClick={(e) => e.stopPropagation()}>
          <DemoMessage />
          <TopCard
            allConversationsLength={allConversationsLength}
            inProgressLength={inProgressLength}
            setHubSelection={setPageSelection}
            user={user as any}
            demoTour="demo-tour-step-3"
          />
          <PanelButton
            action={openChat}
            demoTour="demo-tour-step-4"
            icon={() => <General.Plus />}
            inactive={user.is_demo && !user.is_internal}
            label={t('New conversation')}
          />
          <Styled.Navigation>
            <div style={{ position: 'relative' }}>
              <PanelNav
                action={openHistory}
                icon={() => <General.DotsGrid size={'15'} />}
                demoTour="demo-tour-step-5 onboarding-tour-step-6"
                isActive={['all conversations', ''].includes(pageSelection)}
                label={t('Conversations')}
                notification={inProgressLength}
              />
            </div>
            {(user.is_team_lead || user.is_demo) && (
              <PanelNav
                action={openInsights}
                demoTour="demo-tour-step-8"
                icon={() => <MediaDevices.LightBulb05 size={'15'} />}
                isActive={pageSelection === 'insights'}
                label={t('Insights')}
              />
            )}
            <PanelNav
              action={openJourneys}
              demoTour="demo-tour-step-7"
              icon={() => <Maps.Route size={'15'} />}
              inactive={user.is_demo && !user.is_internal}
              isActive={
                pageSelection === 'journeys' &&
                !(user.is_demo && !user.is_internal)
              }
              label={t('Journeys')}
            />
          </Styled.Navigation>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: 'fit-content',
            }}
          >
            <Styled.BottomText onClick={openAbout}>
              {t('About')}
            </Styled.BottomText>

            {user.is_internal && user.is_demo && (
              <Styled.BottomText onClick={openDemoSettings}>
                {t('Demo settings')}
              </Styled.BottomText>
            )}

            <LanguageToggle getUser={getUser} />
            <Logout />
          </div>
        </Styled.InnerContainer>
      </Styled.Container>
    </ErrorBoundary>
  );
}
