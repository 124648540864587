import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';

export const Card = styled(Cards.BasicCard)`
  border-radius: 20px;
  flex: 1;
  gap: 30px;
  overflow: auto;
  padding: 30px;

  @media (max-width: ${device.tablet}) {
    box-shadow: none;
    justify-content: start;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  overflow-y: auto;
`;

export const Section = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const InnerSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 425px;
  width: 100%;
`;

export const TopSection = styled(Section)`
  margin-top: 70px;

  @media (max-width: ${device.tablet}) {
    margin-top: 10px;
  }
`;

export const BottomSection = styled(Section)`
  margin: 0;
`;

export const Navigation = styled('div')`
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  width: 100%;
  z-index: 5;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const NavButton = styled(Buttons.SecondaryIcon)`
  pointer-events: auto;
  z-index: 3;
`;

export const BackButton = styled(Buttons.TertiaryIcon)`
  pointer-events: auto;
  z-index: 3;
`;
