import React, {
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
} from 'react';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Communication } from 'untitledui-js';
import useAnimatedEllipsis from 'components/pageECoach/chatbar/useAnimatedEllipsis';
import Microphone from 'components/pageECoach/chatbar/Microphone';

// styles
import * as StyledChatbar from 'components/pageECoach/_styles/Chatbar.style';

interface ChatbarProps {
  initialInput?: string;
  onSubmit: (input: string) => void;
  autoFocus?: boolean;
}

export default function Chatbar({
  initialInput,
  onSubmit,
  autoFocus,
}: ChatbarProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const [microphoneDeactivated, setMicrophoneDeactivated] =
    useState<boolean>(true);
  const [splitText, setSplitText] = useState<[string, string]>(['', '']);
  const cursorPosition = useRef<number>(0);
  const [pendingInput, setPendingInput] = useState<string>(initialInput || '');
  const chatbarRef = useRef<HTMLInputElement>(null);
  const ellipsis = useAnimatedEllipsis();

  const handleCursorPosition = (
    event?: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const position = event
      ? (event.target as HTMLInputElement).selectionEnd || 0
      : pendingInput.length;
    cursorPosition.current = position;
    const splitAtIndex = (str: string, index: number): [string, string] => [
      str.slice(0, index).trim(),
      str.slice(index).trim(),
    ];
    setSplitText(splitAtIndex(pendingInput, cursorPosition.current));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(pendingInput);
    setMicrophoneDeactivated(true);
    setPendingInput('');
  };

  const insertTranscript = (transcript: string) => {
    const punctuationCheck = (str: string): boolean => {
      const punctuationRegex = /[.!?;:,]$/;
      return punctuationRegex.test(str);
    };
    const endsWithPunctuation = punctuationCheck(splitText[0]);

    const processedTranscript =
      splitText[0].length > 0 && !endsWithPunctuation
        ? transcript.trim().toLowerCase()
        : transcript;

    const newValue =
      splitText[0].length > 0
        ? [...splitText.slice(0, 1), processedTranscript, ...splitText.slice(1)]
        : [processedTranscript];

    const processed = newValue.join(' ').trim();
    setPendingInput(processed);
  };

  const displayValue = microphoneDeactivated
    ? pendingInput
    : `${pendingInput}${ellipsis}`;

  return (
    <StyledChatbar.Section className="onboarding-tour-step-4">
      <Paper
        sx={{
          alignItems: 'center',
          borderRadius: '20px',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.07)',
          display: 'flex',
          justifyContent: 'space-between',
          p: '6px 12px',
          width: '100%',
        }}
      >
        <Microphone
          deactivated={microphoneDeactivated}
          handleCursorPosition={handleCursorPosition}
          insertTranscript={insertTranscript}
          setMicrophoneDeactivated={setMicrophoneDeactivated}
        />
        <TextField
          onChange={(input: ChangeEvent<HTMLInputElement>) =>
            setPendingInput(input.target.value)
          }
          placeholder={t('Chatbar.Message Nurau')}
          inputProps={{ 'aria-label': t('Chatbar.Message Nurau') }}
          variant="standard"
          multiline
          InputProps={{
            autoFocus,
            disableUnderline: true,
            inputRef: chatbarRef,
            style: { caretColor: 'red' },
          }}
          onInput={handleCursorPosition}
          onKeyDown={(ev: KeyboardEvent<HTMLDivElement>) => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
              ev.preventDefault();
              if (pendingInput.trim().length !== 0)
                handleSubmit(ev as unknown as FormEvent);
            }
          }}
          onSelect={handleCursorPosition}
          sx={{ flexGrow: 1 }}
          value={displayValue}
        />
        <StyledChatbar.Button
          type="submit"
          aria-label={t('Chatbar.Message Nurau')}
          disabled={pendingInput.trim().length === 0}
          onClick={handleSubmit}
        >
          <Communication.Send01 strokeWidth={1.5} />
        </StyledChatbar.Button>
      </Paper>
    </StyledChatbar.Section>
  );
}
