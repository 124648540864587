import type { CSSProperties } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tourContent from 'components/productTours/onboardingTour/OnboardingTourContent';

interface TourStep {
  position?: 'center' | 'top' | 'right' | 'bottom' | 'left';
  selector?: string;
  content: React.ReactNode;
  action?: () => void;
  styles?: {
    maskArea?: (base: CSSProperties) => CSSProperties & { rx?: number };
    [key: string]:
      | ((base: CSSProperties) => CSSProperties & { rx?: number })
      | undefined;
  };
  stepInteraction?: boolean;
  disableWhenSelectorFalsy?: boolean;
}

interface TranslatedContentProps {
  contentKey: string;
}

const TranslatedContent: React.FC<TranslatedContentProps> = ({
  contentKey,
}) => {
  const { t } = useTranslation('productTour');

  return <>{tourContent[contentKey](t)}</>;
};

export default function onboardingTourSteps(
  hideControls,
  hideHighlight,
  tourStartActions: () => void,
  showSidePanel: (show?: boolean) => void
): TourStep[] {
  return [
    {
      selector: '.onboarding-tour-step-1',
      action: tourStartActions,
      content: <TranslatedContent contentKey="step1" />,
      stepInteraction: true,
      disableWhenSelectorFalsy: true,
      styles: hideControls,
    },
    {
      selector: '.onboarding-tour-step-2',
      content: <TranslatedContent contentKey="step2" />,
      stepInteraction: false,
    },
    {
      selector: '.onboarding-tour-step-3',
      content: <TranslatedContent contentKey="step3" />,
      styles: hideControls,
    },
    {
      selector: '.onboarding-tour-step-4',
      content: <TranslatedContent contentKey="step4" />,
      styles: hideControls,
    },
    {
      selector: '.onboarding-tour-step-5',
      content: <TranslatedContent contentKey="step5" />,
    },
    {
      selector: '.onboarding-tour-step-6',
      content: <TranslatedContent contentKey="step6" />,
      styles: hideControls,
      action: () => showSidePanel(true),
    },
    {
      selector: '.onboarding-tour-step-7',
      content: <TranslatedContent contentKey="step7" />,
      stepInteraction: true,
      action: () => showSidePanel(false),
    },
    {
      position: 'center',
      content: <TranslatedContent contentKey="step8" />,
      styles: { ...hideHighlight, ...hideControls },
      stepInteraction: true,
    },
  ];
}
