import styled from 'styled-components';
import colours from 'constants/colours';

export const Section = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  height: 25px;
  justify-content: space-between;
  width: 100%;
`;

export const ProgressIndicator = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const ProgressDot = styled.div`
  align-items: left;
  background-color: ${(props) => props.currentSlide && colours.primary[500]};
  border: ${(props) =>
    !props.currentSlide && `0.5px solid ${colours.neutralWarm[400]}`};
  border-radius: 30px;
  box-sizing: border-box;
  height: 15px;
  width: 15px;
`;
