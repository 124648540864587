import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import IntroCard from 'components/pageAbout/IntroCard';
import PrivacyCard from 'components/pageAbout/PrivacyCard';
import TourButton from 'components/productTours/TourButton';

// context
import { useUser } from 'contexts/UserContext';

// helpers
import supportLink from 'helpers/supportLink';

// styles
import { Files } from 'untitledui-js';
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

export default function AboutECoachCard(): JSX.Element {
  const { t } = useTranslation('pageAbout');
  const { data: user } = useUser();
  const appVersion = localStorage.getItem('app-version');
  const onHelpClick = () => window.open(supportLink(user.language), '_blank');

  return (
    <ErrorBoundary>
      <PageLayout.Page>
        <Styled.Container>
          <Text.SubheadingSemibold>
            {t('pageAbout.header')}
          </Text.SubheadingSemibold>
          <IntroCard />
          <PrivacyCard />
          <Styled.ActionArea>
            <Buttons.Secondary onClick={onHelpClick}>
              <Files.FileQuestion02 />
              {t('intro.help')}
            </Buttons.Secondary>
            <TourButton />
          </Styled.ActionArea>
          <Text.P2Semibold style={{ marginLeft: 'auto' }}>
            {t('pageAbout.version', { version: appVersion })}
          </Text.P2Semibold>
        </Styled.Container>
      </PageLayout.Page>
    </ErrorBoundary>
  );
}
