import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'helpers/dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

// Register the relativeTime plugin
dayjs.extend(relativeTime);

interface Chat {
  resolution_status: string;
  created_at: string;
  modified_at: string;
  [key: string]: any;
}

interface DateProps {
  chat: Chat;
}

export default function DatesAndStatus({ chat }: DateProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const lang = useUserLanguage();
  const now = dayjs();
  dayjs.locale(lang);

  function getOngoingTimeString(date: string): string {
    const start = dayjs(date);
    const duration = start.from(now, true);
    return `${t('allChats.Date.In progress for', { duration })}`;
  }

  function lastUpdatedTimeString(date: string): string {
    const lastUpdated = dayjs(date);
    const duration = lastUpdated.from(now, true);
    return `${t('allChats.Date.Last updated', { duration })}`;
  }

  const getStatusChip = () => {
    if (chat.resolution_status === 'active')
      return (
        <Styled.InProgressStatusChip>
          <Text.P1Semibold>
            {getOngoingTimeString(chat.created_at)}
          </Text.P1Semibold>
        </Styled.InProgressStatusChip>
      );

    if (chat.resolution_status === 'resolved')
      return (
        <Styled.ResolvedStatusChip>
          <Text.P1Semibold>{t('allChats.Status.Resolved')}</Text.P1Semibold>
        </Styled.ResolvedStatusChip>
      );

    if (chat.resolution_status === 'skipped')
      return (
        <Styled.ResolvedStatusChip>
          <Text.P1Semibold>
            {t('allChats.Status.No action needed')}
          </Text.P1Semibold>
        </Styled.ResolvedStatusChip>
      );

    return (
      <Styled.DraftStatusChip>
        <Text.P1Semibold>{t('allChats.Status.Draft')}</Text.P1Semibold>
      </Styled.DraftStatusChip>
    );
  };

  return (
    <Styled.StatusContainer>
      {getStatusChip()}
      <Text.P1>{lastUpdatedTimeString(chat.modified_at)}</Text.P1>
    </Styled.StatusContainer>
  );
}
