import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import page404EN from 'components/page404/_translations/en.json';
import page404FR from 'components/page404/_translations/fr.json';
import pageAboutEN from 'components/pageAbout/_translations/en.json';
import pageAboutFR from 'components/pageAbout/_translations/fr.json';
import pageEcoachEN from 'components/pageECoach/_translations/en.json';
import pageEcoachFR from 'components/pageECoach/_translations/fr.json';
import pageInsightsEN from 'components/pageinsights/_translations/en.json';
import pageInsightsFR from 'components/pageinsights/_translations/fr.json';
import pageJourneyIdEN from 'components/pageJourneyId/_translations/en.json';
import pageJourneyIdFR from 'components/pageJourneyId/_translations/fr.json';
import pageJourneyListEN from 'components/pageJourneyList/_translations/en.json';
import pageJourneyListFR from 'components/pageJourneyList/_translations/fr.json';
import pageLayoutEN from 'components/_pageLayout/_translations/en.json';
import pageLayoutFR from 'components/_pageLayout/_translations/fr.json';
import pageLoginEN from 'components/pageLogin/_translations/en.json';
import pageLoginFR from 'components/pageLogin/_translations/fr.json';
import pageOnboardingEN from 'components/pageOnboarding/_translations/en.json';
import pageOnboardingFR from 'components/pageOnboarding/_translations/fr.json';
import pageSlideshowEN from 'components/pageSlideshow/_translations/en.json';
import pageSlideshowFR from 'components/pageSlideshow/_translations/fr.json';
import pageDemoRegistrationEN from 'components/pageDemoRegistration/_translations/en.json';
import pageDemoRegistrationFR from 'components/pageDemoRegistration/_translations/fr.json';
import productTourEN from 'components/productTours/_translations/en.json';
import productTourFR from 'components/productTours/_translations/fr.json';
import sidePanelEN from 'components/sidePanel/_translations/en.json';
import sidePanelFR from 'components/sidePanel/_translations/fr.json';

interface Resources {
  [key: string]: {
    [namespace: string]: Record<string, unknown>;
  };
}

const resources: Resources = {
  en: {
    page404: page404EN,
    pageAbout: pageAboutEN,
    pageEcoach: pageEcoachEN,
    pageInsights: pageInsightsEN,
    pageJourneyId: pageJourneyIdEN,
    pageJourneyList: pageJourneyListEN,
    pageLayout: pageLayoutEN,
    pageLogin: pageLoginEN,
    pageOnboarding: pageOnboardingEN,
    pageSlideshow: pageSlideshowEN,
    pageDemoRegistration: pageDemoRegistrationEN,
    productTour: productTourEN,
    sidePanel: sidePanelEN,
  },
  fr: {
    page404: page404FR,
    pageAbout: pageAboutFR,
    pageEcoach: pageEcoachFR,
    pageInsights: pageInsightsFR,
    pageJourneyId: pageJourneyIdFR,
    pageJourneyList: pageJourneyListFR,
    pageLayout: pageLayoutFR,
    pageLogin: pageLoginFR,
    pageOnboarding: pageOnboardingFR,
    pageSlideshow: pageSlideshowFR,
    pageDemoRegistration: pageDemoRegistrationFR,
    productTour: productTourFR,
    sidePanel: sidePanelFR,
  },
};

i18n
  .use(LanguageDetector) // use LanguageDetector
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: process.env.REACT_APP_DEBUG === 'true',

    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en',
    load: 'languageOnly',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
