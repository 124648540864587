import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import { General } from 'untitledui-js';

// components
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';

// style
import * as Header from 'components/pageECoach/_styles/Toolbar.style';
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';

interface DocumentShareButtonProps {
  docURL: string;
  onPage?: boolean;
  bucketUrl?: string;
  useSourceUrl?: boolean;
  sourceUrl?: string;
}

export default function DocumentShareButton({
  docURL,
  onPage,
  bucketUrl,
  useSourceUrl,
  sourceUrl,
}: DocumentShareButtonProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = () => setIsOpenConfirmDialog(true);
  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);

  const getBaseUrl = (): string => {
    const parsedUrl = new URL(window.location.href);
    return parsedUrl.origin;
  };

  const url = `${getBaseUrl()}${docURL}`;

  const getConfirmDialogText = (): string => {
    if (useSourceUrl && sourceUrl) {
      return t('documentDisplay.DocumentShareButton.Share link copied!');
    }

    if (bucketUrl?.startsWith('https')) {
      return t('documentDisplay.DocumentShareButton.Share link copied!');
    }

    return t(
      'documentDisplay.DocumentShareButton.Link copied! Please note that this link will only work if the recipient has a valid Nurau account.'
    );
  };

  const confirmDialog = (
    <ConfirmDialog
      cancelLabel={t('documentDisplay.DocumentShareButton.Close')}
      contentText={getConfirmDialogText()}
      handleClose={handleConfirmDialogClose}
      isOpen={true}
    />
  );

  const copyToClipboard = (): void => {
    posthog?.capture('user_clicks_share_document');
    let urlToCopy = url;

    if (useSourceUrl && sourceUrl) {
      urlToCopy = sourceUrl;
    } else if (bucketUrl?.startsWith('https')) {
      urlToCopy = bucketUrl;
    }

    navigator.clipboard
      .writeText(urlToCopy)
      .then(handleConfirmDialogOpen)
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const ButtonComponent = onPage ? Header.ToolbarButton : Styled.Button;

  return (
    <>
      <ButtonComponent onClick={copyToClipboard}>
        <General.Share03 size={'15'} />
        {t('documentDisplay.DocumentShareButton.Share')}
      </ButtonComponent>
      {isOpenConfirmDialog && confirmDialog}
    </>
  );
}
