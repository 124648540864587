import React from 'react';

// styles
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import * as Text from 'components/_styles/Text.style';

interface ClickableInputProps {
  className?: string;
  inputAction?: () => void;
  inputText?: string;
  inputType?: string;
}

export default function ClickableInput({
  className,
  inputAction,
  inputText,
  inputType,
}: ClickableInputProps): JSX.Element {
  if (inputType === 'dark')
    return (
      <StyledPromptControls.PromptControlDark
        className={className || ''}
        onClick={inputAction}
      >
        <Text.P2Semibold>{inputText}</Text.P2Semibold>
      </StyledPromptControls.PromptControlDark>
    );

  if (inputType === 'primary')
    return (
      <StyledPromptControls.PromptControlPrimary
        className={className || ''}
        onClick={inputAction}
      >
        <Text.P2Semibold>{inputText}</Text.P2Semibold>
      </StyledPromptControls.PromptControlPrimary>
    );

  // default: if (inputType === 'light')
  return (
    <StyledPromptControls.PromptControl
      className={className || ''}
      onClick={inputAction}
    >
      <Text.P2Semibold>{inputText}</Text.P2Semibold>
    </StyledPromptControls.PromptControl>
  );
}
