import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LogoText from 'assets/logoText.svg';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Styled from 'components/pageOnboarding/_styles/Welcome.style';

function SlideWelcome(): JSX.Element {
  const { t } = useTranslation('pageOnboarding');
  const { data: user } = useUser();

  const [logoVisible, setLogoVisible] = useState(true);
  const [nameVisible, setNameVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLogoVisible(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!logoVisible)
      setTimeout(() => {
        setNameVisible(true);
      }, 1000);
  }, [logoVisible]);

  useEffect(() => {
    if (nameVisible)
      setTimeout(() => {
        setNameVisible(false);
      }, 2500);
  }, [nameVisible]);

  return (
    <Styled.Container>
      <Styled.LogoText logoVisible={logoVisible} src={LogoText} />
      <Styled.WelcomeText nameVisible={nameVisible}>
        {t('SlideWelcome', { name: user.first_name })}
      </Styled.WelcomeText>
    </Styled.Container>
  );
}

export default SlideWelcome;
