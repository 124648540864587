import styled from 'styled-components';

export const Page = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

export const InnerPage = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  justify-content: space-between;
  max-width: 600px;
  padding: 20px;
  width: 100%;
`;

export const LogoText = styled.img`
  display: block;
  height: 25px;
  margin-right: 12px;
  width: auto;
`;

export const LogoIcon = styled.img`
  display: block;
  height: 40px;
  max-width: 40px;
  width: auto;
`;

export default Page;
