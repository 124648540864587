import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';
import ChatStart from 'components/pageECoach/chatStart/_index';
import ChatDatestamp from 'components/pageECoach/ChatDatestamp';
import ChatStatusProgress from 'components/pageECoach/ChatStatusProgress';
import ConversationItemsCard from 'components/pageECoach/convoPrep/ConversationItemsCard';
import IntroMessage from 'components/pageECoach/chatStart/IntroMessage';
import RAGCard, { RAGOutput } from 'components/pageECoach/RAGCard';
import SuggestionItemsCard from 'components/pageECoach/suggestions/SuggestionItemsCard';
import { Stack } from '@mui/material';
import Loader from 'components/pageECoach/loaders/Loader';

// context & hooks
import useAutoScroll from 'hooks/useAutoScroll';
import useChatStatus from 'hooks/useChatStatus';
import usePosthogChatbarTracker from 'hooks/usePosthogChatbarTracker';
import useWebsocketSetup from 'hooks/useWebsocketSetup';

// utils
import routesConfig from 'constants/routesConfig.json';
import { OutputType, UserInputType, UserInputTypeValues } from './utils';
import ECoachConfirmationInput from './ECoachConfirmationInput';
import AboutCard from './convoPrep/AboutCard';

interface ChatGuideData {
  chatStartEnded: boolean;
  displayPromptHelper: boolean;
  inProgress: boolean;
  promptHelperGoal: string;
  promptHelperTheme: string;
}

interface Step {
  created_at: string;
  user_inputs?: Array<{ message: string }>;
  outputs?: Array<{
    output_type: number;
    message?: string;
    rag?: {
      summary: string;
      documents: any[];
    };
    [key: string]: any;
  }>;
}

interface Session {
  id: string;
  steps?: Step[];
  [key: string]: any;
}

interface InputPending {
  message: string;
  inputType: number;
}

interface ECoachSessionProps {
  activeSession?: Session;
  loading?: boolean;
  chatGuideData: ChatGuideData;
  createSession: (url: string, method: string) => void;
  handleChatGuidance: (data: Partial<ChatGuideData>) => void;
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  sendUserInput?: (text: string, type: UserInputTypeValues) => void;
}

export default function ECoachSession({
  activeSession,
  loading,
  chatGuideData,
  createSession,
  handleChatGuidance,
  steps,
  setSteps,
}: ECoachSessionProps): JSX.Element {
  const [inputPendingSend, setInputPendingSend] = useState<InputPending | null>(
    null
  );
  const { updateStatus } = useChatStatus();
  const { captureSubmission } = usePosthogChatbarTracker();
  const messagesEndRef = useAutoScroll(steps);
  const { t } = useTranslation('pageEcoach');
  const { sendJsonMessage, lastJsonMessage } = useWebsocketSetup(
    routesConfig.ECOACH.MAESTRO_WS
  );

  const sendUserInput = useCallback(
    (
      message: string,
      inputType: (typeof UserInputType)[keyof typeof UserInputType] = UserInputType.BACK_AND_FORTH_CONVERSATION_INPUT
    ) => {
      captureSubmission(inputType);

      if (!activeSession) {
        createSession(`${routesConfig.ECOACH.SESSIONS}`, 'post');
        setInputPendingSend({ inputType, message });
        return;
      }

      setInputPendingSend(null);
      sendJsonMessage({
        action: 'evaluate',
        request_id: Date.now(),
        pk: activeSession.id,
        data: { input_type: inputType, message },
      });

      setSteps((prevSteps) => [
        ...prevSteps,
        { created_at: new Date().toISOString(), user_inputs: [{ message }] },
      ]);
    },
    [activeSession, captureSubmission, createSession, sendJsonMessage, setSteps]
  );

  // #region Send first input once session has been created
  useEffect(() => {
    if (activeSession && inputPendingSend) {
      sendUserInput(
        inputPendingSend.message,
        inputPendingSend.inputType as any
      );
    }
  }, [activeSession, inputPendingSend, sendUserInput]);
  // #endregion Send first input once session has been created

  // #region Load session steps
  useEffect(() => {
    if (!activeSession) setSteps([]);

    // Only override steps once session steps have been loaded
    if (activeSession?.steps && activeSession?.steps?.length !== 0) {
      setSteps(activeSession.steps);
    }

    // We don't want to update each time steps.length updates, so it's not in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession, setSteps]);
  // #endregion Load session steps

  // #region Receive eCoach response
  useEffect(() => {
    if (lastJsonMessage === null) return;
    // @ts-expect-error - Type issues with lastJsonMessage
    if (lastJsonMessage.type === 'validation_error') return;

    // @ts-expect-error - Type issues with lastJsonMessage
    const { data } = lastJsonMessage;

    if (!data) return;

    setSteps((prevSteps) => {
      const filteredSteps = prevSteps.filter(
        (step) => step.outputs !== undefined
      );
      return [...filteredSteps, data];
    });
  }, [lastJsonMessage, setSteps]);
  // #endregion Receive eCoach response

  const messageCards = useMemo(() => {
    if (!activeSession) return [];
    return steps?.map((step, i) => {
      const timestamps = [];
      timestamps.push(
        <ChatDatestamp
          index={i}
          timestamp={step.created_at}
          prevTimestamp={steps[i - 1]?.created_at}
        />
      );

      const userInputCards = [];
      if (step.user_inputs) {
        userInputCards.push(
          Object.values(step.user_inputs).map((userInput, index) => (
            <ChatBubble
              key={index}
              simpleString={userInput.message}
              sentByUser
            />
          ))
        );
      }

      const outputCards = [];
      if (step.outputs) {
        outputCards.push(
          Object.values(step.outputs).map((output, index) => {
            if (output.output_type === OutputType.CONVERSATION_PLAN_ABOUT)
              return <AboutCard key={index} />;

            if (output.output_type === OutputType.SUGGESTIONS)
              return (
                <SuggestionItemsCard
                  activeSession={activeSession.id}
                  stepOutput={output}
                  key={index}
                />
              );

            if (output.output_type === OutputType.RAG_SUMMARY)
              return output.rag ? (
                <RAGCard
                  activeSession={activeSession}
                  key={index}
                  output={output as RAGOutput}
                />
              ) : null;

            if (output.output_type === OutputType.CONVERSATION_PLAN)
              return (
                <ConversationItemsCard
                  activeSession={activeSession}
                  key={index}
                  stepOutput={output}
                />
              );

            return <ChatBubble key={index} simpleString={output.message} />;
          })
        );
      } else {
        outputCards.push(
          <ChatBubble key={`loading-bubble-${i}`}>
            {React.createElement(Loader, { type: 'typing' } as any)}
          </ChatBubble>
        );
      }

      return [...timestamps, ...userInputCards, ...outputCards];
    });
  }, [activeSession, steps]);

  const inputComponent = useMemo(() => {
    if (!steps) return <></>;

    const lastStep = steps[steps.length - 1];
    if (!lastStep?.outputs) {
      return <></>;
    }

    const lastOutput = lastStep.outputs[lastStep.outputs.length - 1];
    switch (lastOutput?.output_type) {
      case OutputType.CONVERSATION_PREP_WITH_WHO:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_WITH_WHO)
            }
          />
        );
      case OutputType.CONVERSATION_PREP_GOAL:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_GOAL)
            }
          />
        );
      case OutputType.CONVERSATION_PREP_WORRIED_BY:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_WORRIED_BY)
            }
          />
        );
      case OutputType.CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION:
        return (
          <ECoachConfirmationInput
            sendUserInput={sendUserInput}
            inputType={UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER}
          />
        );
      case OutputType.RESOLUTION_TAKEN_ACTION_QUESTION:
        return (
          <ECoachConfirmationInput
            sendUserInput={sendUserInput}
            inputType={UserInputType.RESOLUTION_TAKEN_ACTION_ANSWER}
            alternativeOption={{
              action: () => updateStatus(activeSession, 'skipped'),
              text: t('Skip'),
            }}
          />
        );
      case OutputType.RESOLUTION_IS_RESOLVED_QUESTION:
        return (
          <ECoachConfirmationInput
            sendUserInput={sendUserInput}
            inputType={UserInputType.RESOLUTION_IS_RESOLVED_ANSWER}
          />
        );
      case OutputType.RESOLUTION_RESOLVED_TAKEN_ACTION_QUESTION:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.OUTCOME_VERIFICATION_ANSWER)
            }
          />
        );
      case OutputType.OUTCOME_CONFIDENCE_QUESTION:
        return (
          <ECoachConfirmationInput
            sendUserInput={sendUserInput}
            inputType={UserInputType.RESOLUTION_CONFIDENCE_ANSWER}
          />
        );
      case OutputType.OUTCOME_VERIFICATION_QUESTION:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.OUTCOME_VERIFICATION_ANSWER)
            }
          />
        );
      case OutputType.UNBLOCKER_QUESTION:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.UNBLOCKER_ANSWER)
            }
          />
        );
      case OutputType.UNBLOCKER_FOLLOW_UP_QUESTION:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.UNBLOCKER_FOLLOW_UP_ANSWER)
            }
          />
        );
      case OutputType.CHALLENGE_VERIFICATION_QUESTION:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(
                message,
                UserInputType.CHALLENGE_VERIFICATION_ANSWER
              )
            }
          />
        );
      default:
        return <Chatbar autoFocus onSubmit={sendUserInput} />;
    }
  }, [activeSession, sendUserInput, steps, updateStatus, t]);

  return (
    <Stack spacing={3} style={{ flex: 1, justifyContent: 'space-between' }}>
      {steps && <ChatStatusProgress steps={steps} />}
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: '30px',
        }}
      >
        {steps.length > 0 && <ChatDatestamp timestamp={steps[0].created_at} />}
        <IntroMessage key="IntroMessage" />
        {activeSession && messageCards}
      </div>
      {!activeSession && !loading && (
        <ChatStart
          key="start_chat"
          chatGuideData={chatGuideData}
          handleChatGuidance={handleChatGuidance}
          sendUserInput={sendUserInput}
        />
      )}
      {activeSession && inputComponent}
      <div ref={messagesEndRef} style={{ margin: 0 }} />
    </Stack>
  );
}
