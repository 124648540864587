import React from 'react';
import { Trans } from 'react-i18next';

// assets
import Arrow from 'components/pageOnboarding/_assets/Arrow.png';

// styles
import * as Styled from 'components/pageOnboarding/_styles/Slides.style';

interface SlideGeneralProps {
  currentSlide: number;
}

function SlideGeneral({ currentSlide }: SlideGeneralProps): JSX.Element {
  const slideContents = [
    [
      'pageOnboarding:SlideGeneral.slide1a',
      'pageOnboarding:SlideGeneral.slide1b',
    ],
    [
      'pageOnboarding:SlideGeneral.slide2a',
      'pageOnboarding:SlideGeneral.slide2b',
    ],
    ['pageOnboarding:SlideGeneral.slide3'],
    ['pageOnboarding:SlideGeneral.slide4'],
  ];

  return (
    <Styled.Slide>
      {currentSlide === 3 && <div style={{ flex: 1 }}></div>}

      {slideContents[currentSlide].map((translationKey, i) => (
        <Styled.Content key={i}>
          <Trans
            i18nKey={translationKey}
            components={{
              primary: <span style={{ color: 'red' }} />,
            }}
          />
        </Styled.Content>
      ))}

      {currentSlide === 3 && (
        <img
          src={Arrow}
          style={{
            position: 'relative',
            left: '30px',
            flex: 1,
            objectFit: 'contain',
          }}
        />
      )}
    </Styled.Slide>
  );
}

export default SlideGeneral;
