import type { CSSProperties } from 'react';
import React from 'react';
import routesConfig from 'constants/routesConfig.json';
import demoTourSteps from 'components/productTours/demoTour/demoTourSteps';
import onboardingTourSteps from 'components/productTours/onboardingTour/onboardingTourSteps';

// hooks
import { useUser } from 'contexts/UserContext';
import useTourState from 'components/productTours/useTourState';

// Define a History interface if it's not available
interface History {
  push: (path: string | { search: string }) => void;
  replace: (path: string | { [key: string]: string }) => void;
}

interface TourStep {
  position?: 'center' | 'top' | 'right' | 'bottom' | 'left';
  selector?: string;
  content: React.ReactNode;
  action?: (element?: Element) => void;
  styles?: {
    maskArea?: (base: CSSProperties) => CSSProperties & { rx?: number };
    [key: string]:
      | ((base: CSSProperties) => CSSProperties & { rx?: number })
      | undefined;
  };
  stepInteraction?: boolean;
  disableWhenSelectorFalsy?: boolean;
}

export default function useTourConfig(history: History): TourStep[] {
  const { data: user } = useUser();
  const [, hasSeenTour] = useTourState({
    isDemoUser: user?.is_demo,
  });

  const hideHighlight = {
    maskArea: (base: CSSProperties): CSSProperties & { rx?: number } => ({
      ...base,
      display: 'none',
    }),
  };

  const hideControls = {
    controls: (base) => ({
      ...base,
      justifyContent: 'center',
    }),
    arrow: () => ({
      display: 'none', // Hides the navigation arrows
    }),
  };

  const tourStartActions = () => {
    hasSeenTour();
    const route = user?.is_demo
      ? `${routesConfig.ALL_CONVOS_PAGE}?tour=true`
      : `${routesConfig.CONVO_PAGE}?tour=true`;
    history.replace(route);
  };

  const showSidePanel = (show?: boolean) => {
    history.replace(
      `${routesConfig.ALL_CONVOS_PAGE}?showpanel=${show}&tour=true`
    );
  };

  return user?.is_demo
    ? demoTourSteps(hideHighlight, tourStartActions, showSidePanel)
    : onboardingTourSteps(
        hideControls,
        hideHighlight,
        tourStartActions,
        showSidePanel
      );
}
