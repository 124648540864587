import React from 'react';

// components
import StyledContent from 'components/productTours/StyledContent';

// styles
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

type TFunction = (key: string) => string;

interface TourContent {
  [key: string]: (t: TFunction) => React.ReactNode;
}

const demoTourContent: TourContent = {
  step1: (t) => (
    <StyledContent
      includeLogo
      header={t('demoTour.Welcome to Nurau!')}
      content={[
        t(
          "demoTour.We combine emotional intelligence with your company's policies to help you lead effectively."
        ),
      ]}
    />
  ),

  step2: (t) => (
    <StyledContent
      includeLogo
      content={[
        t(
          "demoTour.We'll be your guide through challenging conversations, providing real-time coaching to help you inspire and engage your team."
        ),
      ]}
    />
  ),

  step3: (t) => (
    <StyledContent
      content={[t('demoTour.This dashboard shows your engagement level.')]}
    />
  ),

  step4: (t) => (
    <StyledContent
      content={[
        t(
          'demoTour.When you start a new conversation, it will show as active under All conversations.'
        ),
      ]}
    />
  ),

  step5: (t) => (
    <StyledContent
      content={[
        t(
          'demoTour.Here are your active conversations. Nurau will help you transform challenging situations into productive outcomes.'
        ),
      ]}
    />
  ),

  step6: (t) => (
    <StyledContent
      content={[
        t(
          'demoTour.Your conversations are completely private. Nothing you share will be seen by your manager or anyone at your company.'
        ),
      ]}
    />
  ),
  step7: (t) => (
    <StyledContent
      content={[
        t(
          'demoTour.Looking to hone in on key leadership skills? Tap into Journeys, our series of short microlearnings.'
        ),
      ]}
    />
  ),

  step8: (t) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Text.P2>
        {t(
          'demoTour.Click here to view your insights dashboard, where you will find key data, like your'
        )}
      </Text.P2>
      <Text.P2>
        <b>{t('demoTour.Return on investment analysis')}</b>
        {t(
          "demoTour.Key stats about your company's labour cost reductions in comparison to other companies in your industry."
        )}
      </Text.P2>
      <Text.P2>
        <b>{t('demoTour.Escalation management and risk prevention')}</b>
        {t(
          'demoTour.Be the first to know about site specific challenges and risks, along with how to best mitigate them.'
        )}
      </Text.P2>
      <Text.P2>
        <b>{t('demoTour.User engagement monitoring')}</b>
        {t('demoTour.Satisfaction and platform usage.')}
      </Text.P2>
      <div
        style={{
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          padding: '20px',
          backgroundColor: colours.blue[50],
        }}
      >
        <Text.P2>
          {t(
            "demoTour.You're on a demo account, so we've pre-populated your dashboard with mock data in order for you to get an idea of the sorts of insights you will get."
          )}
        </Text.P2>
      </div>
    </div>
  ),

  step9: (t) => (
    <StyledContent
      content={[
        t(
          "demoTour.Ready to get started? Choose an active conversation from your list that you'd like guidance on. Whether it's addressing performance, handling conflicts, or having sensitive discussions, we are here to help."
        ),
      ]}
    />
  ),

  step10: (t) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Text.P2>
        {t(
          "demoTour.You're on a demo account, so we've added some example conversations for you to play with."
        )}
      </Text.P2>
      <Text.P2>
        {t('demoTour.Select what you like to do with this conversation:')}
      </Text.P2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.mint[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t(
              "demoTour.Mark as complete - if you've finished addressing this situation"
            )}
          </Text.P2>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.warning[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t(
              "demoTour.Keep in progress - if you'll need more guidance later"
            )}
          </Text.P2>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.neutralCool[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t('demoTour.Remove from goals - if this is no longer a priority')}
          </Text.P2>
        </div>
      </div>
      <div style={{ height: '60px' }}></div>
    </div>
  ),
};

export default demoTourContent;
