import React from 'react';

// assets
import LogoText from 'assets/logoBlackText.svg';

// styles
import * as Text from 'components/_styles/Text.style';

interface StyledContentProps {
  children?: React.ReactNode;
  custom?: boolean;
  includeLogo?: boolean;
  header?: string;
  content?: any[];
  hasButton?: boolean;
}

const StyledContent: React.FC<StyledContentProps> = ({
  children,
  custom,
  includeLogo,
  header,
  content,
  hasButton,
}) => {
  if (custom)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {children}
      </div>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <div
        style={{
          alignItems: hasButton ? 'center' : 'left',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        {includeLogo && <img src={LogoText} style={{ width: '150px' }} />}
        {header && <Text.H5Semibold>{header}</Text.H5Semibold>}
        {content &&
          content.map((paragraph, i) => <Text.P2 key={i}>{paragraph}</Text.P2>)}
      </div>
      {hasButton && <div style={{ height: '50px' }}></div>}
    </div>
  );
};

export default StyledContent;
