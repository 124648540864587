import styled from 'styled-components';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

interface StatusProps {
  status?: string;
}

export const ProgressContainer = styled('div')`
  display: flex;
  justify-content: right;
  pointer-events: none;
  position: sticky;
  top: -30px;
  z-index: 10;

  @media (max-width: ${device.tablet}) {
    top: -10px;
  }
`;

export const InnerContainer = styled('div')`
  align-items: center;
  background-color: ${colours.neutralWarm[100]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 10px 20px;
`;

export const Dot = styled('div')`
  background-color: ${colours.neutralWarm[300]};
  border-radius: 20px;
  height: 10px;
  width: 10px;
`;

export const Progress = styled(Text.P1Semibold)<StatusProps>`
  color: ${(props) =>
    props.current.toString() === 'true'
      ? colours.neutralWarm[700]
      : colours.neutralWarm[500]};
`;
