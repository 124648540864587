import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const LogoText = styled.img`
  opacity: ${(props) => (props.logoVisible.toString() === 'true' ? 1 : 0)};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: ${(props) =>
    props.logoVisible.toString() === 'true' ? 'visible' : 'hidden'};
`;

export const WelcomeText = styled(Text.H2Extrabold)`
  color: ${colours.neutralWarm[800]};
  text-align: center;

  position: absolute;
  white-space: nowrap;

  opacity: ${(props) => (props.nameVisible.toString() === 'true' ? 1 : 0)};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: ${(props) =>
    props.nameVisible.toString() === 'true' ? 'visible' : 'hidden'};

  @media (max-width: ${device.tablet}) {
    font-size: 23px;
    line-height: 28px;
  }
`;
