interface PromptOption {
  label: string;
  prompt: string;
}

interface HelperPrompts {
  [key: string]: PromptOption[];
}

const helperPrompts: HelperPrompts = {
  'Preparing for a difficult conversation': [
    {
      label: 'Conducting a performance review',
      prompt: 'how to organize the performance review of a member of my team.',
    },
    {
      label: 'Disciplining an employee',
      prompt:
        'how to talk to an employee about their poor performance or bad behaviour.',
    },
    {
      label: 'Resolving a team conflict',
      prompt:
        'effective methods for resolving friction between multiple members of my team.',
    },
    {
      label: 'Offering support',
      prompt:
        "how to talk to a team member who seems like they're struggling emotionally.",
    },
  ],
  'Overcoming a challenge in my team': [
    {
      label: 'Boost team performance',
      prompt: 'steps I can take to overcome a team performance challenge.',
    },
    {
      label: 'Manage difficult conversations',
      prompt:
        'how to effectively manage a complex situation with a team member.',
    },
    {
      label: 'Resolve team conflicts',
      prompt: 'effective methods for resolving a team conflict.',
    },
    {
      label: 'Support team members',
      prompt: "how to support a team member who's struggling.",
    },
    {
      label: 'Improve team morale',
      prompt: "strategies for improving my team's morale.",
    },
  ],
  "Elevating my team's success": [
    {
      label: 'Celebrate team success',
      prompt: "creative ways to recognize and reward my team's hard work.",
    },
    {
      label: 'Improve team bonding',
      prompt:
        'which activities or practices can help build stronger team camaraderie.',
    },
  ],
  'Growing as a leader': [
    {
      label: 'Upgrade leadership skills',
      prompt:
        'what specific areas I should focus on to enhance my leadership skills.',
    },
    {
      label: 'Handle leadership stress',
      prompt: 'practical techniques for managing stress as a leader.',
    },
    {
      label: 'Balance work and life',
      prompt: 'how to effectively balance my workload and personal time.',
    },
  ],
  'Improving workplace interactions': [
    {
      label: 'Encourage cross-team collaboration',
      prompt:
        'some strategies for fostering collaboration between departments.',
    },
    {
      label: 'Foster company culture',
      prompt:
        'what actions I can take to contribute to a more positive and inclusive company culture.',
    },
    {
      label: 'Boost employee engagement',
      prompt:
        'what initiatives I can introduce to enhance employee satisfaction and engagement.',
    },
  ],
};

export default helperPrompts;
export type { PromptOption, HelperPrompts };
