import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

interface ButtonContinueProps {
  currentSlide: number;
  nextSlide: () => void;
}

function ButtonContinue({
  currentSlide,
  nextSlide,
}: ButtonContinueProps): JSX.Element {
  const { t } = useTranslation('pageOnboarding');

  const getLabel = () => {
    if (currentSlide === 3) return t('Buttons.start a conversation');
    return t('Buttons.continue');
  };

  return (
    <Buttons.Primary centerText fullWidth onClick={nextSlide}>
      {getLabel()}
    </Buttons.Primary>
  );
}

export default ButtonContinue;
