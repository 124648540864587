import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// components
import ChatLanding from 'components/pageECoach/chatStart/ChatLanding';
import PromptHelper from 'components/pageECoach/promptHelper/_index';

// utils
import { UserInputType } from 'components/pageECoach/utils';

interface ChatGuideData {
  displayPolicyHelper?: boolean;
  displayPromptHelper: boolean;
  chatStartEnded: boolean;
  inProgress: boolean;
  promptHelperGoal: string | null | any;
  promptHelperTheme: string | null;
}

interface ChatStartProps {
  chatGuideData: ChatGuideData;
  handleChatGuidance: (updates: Partial<ChatGuideData>) => void;
  sendUserInput: (text: string, type: number) => void;
}

export default function ChatStart({
  chatGuideData,
  handleChatGuidance,
  sendUserInput,
}: ChatStartProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const openPromptHelper = (): void => {
    const updates: Partial<ChatGuideData> = {
      displayPolicyHelper: false,
      displayPromptHelper: true,
      chatStartEnded: false,
      inProgress: true,
    };

    handleChatGuidance(updates);
    posthog?.capture('user_clicks_prompt_helper');
  };

  const activatePolicyHelper = (): void => {
    sendUserInput(
      t('chatStart.I need information about my company policies'),
      UserInputType.INITIAL_PROMPT
    );
    posthog?.capture('user_clicks_policy_helper');
  };

  const startConvoPrep = (): void => {
    sendUserInput(
      t('chatStart.I need help preparing for a conversation with someone.'),
      UserInputType.CONVERSATION_PREP_START
    );
    posthog?.capture('user_clicks_convo_prep_form');
  };

  const onChallengeSubmission = (userInput: string): void => {
    const updates: Partial<ChatGuideData> = {
      inProgress: false,
      chatStartEnded: true,
    };
    handleChatGuidance(updates);
    posthog?.capture('user_submits_eCoach_challenge');

    if (
      chatGuideData.promptHelperTheme ===
      'Preparing for a difficult conversation'
    ) {
      sendUserInput(userInput, UserInputType.CONVERSATION_PREP_WORRIED_BY);
    } else {
      sendUserInput(userInput, UserInputType.INITIAL_PROMPT);
    }
  };

  if (
    chatGuideData.displayPromptHelper ||
    chatGuideData.promptHelperTheme === 'Preparing for a difficult conversation'
  )
    return (
      <PromptHelper
        chatGuideData={chatGuideData}
        handleChatGuidance={handleChatGuidance}
        onSubmit={onChallengeSubmission}
      />
    );

  return (
    <ChatLanding
      activatePolicyHelper={activatePolicyHelper}
      handleChatGuidance={handleChatGuidance}
      onSubmit={onChallengeSubmission}
      openPromptHelper={openPromptHelper}
      startConvoPrep={startConvoPrep}
    />
  );
}
