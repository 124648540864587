/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TourProvider, useTour, StepType } from '@reactour/tour';

// components
import CloseTour from 'components/productTours/CloseTour';
import PageRoutes from 'components/_routes/PageRoutes';
import SidePanelRoutes from 'components/_routes/SidePanelRoutes';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useTourConfig from 'components/productTours/useTourConfig';
import useViewportHeight from 'hooks/useViewportHeight';
import useWindowSize from 'hooks/useWindowResize';

// styles
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';

// Enhanced tour handler component with DOM event capture
const TourClickCaptureHandler = () => {
  const { currentStep, steps, setCurrentStep, setIsOpen } = useTour();

  useEffect(() => {
    // Function to advance to the next step
    const advanceToNextStep = () => {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        setIsOpen(false);
      }
    };

    const handleClick = (event: MouseEvent) => {
      const step = steps[currentStep];
      if (!step) return;

      // Try to find if we clicked on or near the highlighted element
      if (step.selector) {
        try {
          const targetElement = document.querySelector(step.selector as string);
          const highlightElement = document.querySelector(
            '[data-tour-elem="highlight"]'
          );

          const clickedTarget =
            (targetElement && targetElement.contains(event.target as Node)) ||
            (highlightElement &&
              highlightElement.contains(event.target as Node));

          if (clickedTarget) {
            // Execute step action if defined
            if (step.action) {
              try {
                step.action(targetElement as Element);
                // Advance to the next step after a delay
                setTimeout(advanceToNextStep, 300);
              } catch (error) {
                console.error('Error executing step action:', error);
              }
            } else {
              // No action defined, advance to the next step
              setTimeout(advanceToNextStep, 300);
            }
          }
        } catch (error) {
          console.error('Error handling click:', error);
        }
      }
    };

    // Add global capture phase click listener
    document.addEventListener('click', handleClick, true);

    // Cleanup
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [currentStep, steps, setCurrentStep, setIsOpen]);

  return null; // This component doesn't render anything
};

export default function PageLayout(): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  const { data: user, callApi: getUser } = useUser();
  const windowSize = useWindowSize();
  const windowWidth = windowSize[0];

  const tourSteps = useTourConfig(history);

  useViewportHeight();

  const inECoach = location.pathname.toLowerCase().includes('ecoach');
  const inOnboarding = location.pathname.includes('onboarding');

  return (
    <TourProvider
      key={user?.language}
      steps={tourSteps as StepType[]}
      disableInteraction={false} // Allow interaction with highlighted elements
      components={{
        Close: ({ onClick }) => {
          const { currentStep } = useTour();
          return (
            <CloseTour
              onClick={onClick}
              currentStep={currentStep}
              numSteps={tourSteps.length}
              tourType={user.is_demo ? 'demo' : 'onboarding'}
            />
          );
        },
      }}
      onClickMask={() => {}} // prevent tour from closing on background click
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#FF4D52',
          borderRadius: '20px',
          minWidth: `${windowWidth > 768 ? '350px' : '250px'}`,
          paddingTop: '40px',
        }),
        maskArea: (base) => ({ ...base, rx: 20 }),
        maskWrapper: (base) => ({ ...base, opacity: '0.5' }),
        badge: (base) => ({ ...base, display: 'none' }), // tour step number
        controls: (base) => ({ ...base, marginTop: 50 }),
        close: (base) => ({ ...base, left: 'auto', right: 20, top: 20 }),
      }}
    >
      <TourClickCaptureHandler />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Container.ScreenContainer id="screen" inDemo={user.is_demo}>
          {!inOnboarding && <SidePanelRoutes getUser={getUser} />}
          <Container.PageContainer inECoach={inECoach}>
            <PageRoutes />
          </Container.PageContainer>
        </Container.ScreenContainer>
      </div>
    </TourProvider>
  );
}
