import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import { useHistory, useLocation } from 'react-router-dom';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useTourState from 'components/productTours/useTourState';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import { Shapes } from 'untitledui-js';

export default function TourButton(): React.ReactElement {
  const { t } = useTranslation('productTour');
  const { data: user } = useUser();
  const [isTourOpen] = useTourState({
    isDemoUser: user?.is_demo,
  });
  const { setIsOpen, setCurrentStep, currentStep, steps } = useTour();
  const history = useHistory();
  const location = useLocation();

  const startTour = () => {
    setIsOpen(true);
    const isFinalStep = currentStep === steps.length - 1;
    if (isFinalStep) setCurrentStep(0);

    // Update the URL to include the tourOpen parameter
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tour', 'true');
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  // Set tour state based on useTourState
  useEffect(() => {
    setIsOpen(isTourOpen);
  }, [isTourOpen, setIsOpen]);

  return (
    <Buttons.Secondary onClick={startTour}>
      <Shapes.Star06 />
      {t('Take a tour')}
    </Buttons.Secondary>
  );
}
