import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// components
import TourButton from 'components/productTours/TourButton';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useViewportHeight from 'hooks/useViewportHeight';

// styles
import * as Container from 'components/sidePanel/_styles/DemoMessage.style';
import * as Text from 'components/_styles/Text.style';
import { Link } from '@mui/material';

export default function DemoMessage(): React.ReactElement {
  const { t } = useTranslation('sidePanel');
  const { data: user } = useUser();

  useViewportHeight();

  if (!user?.is_demo) return <></>;

  return (
    <Container.DemoMessage>
      <Text.P2Semibold>
        <Trans
          i18nKey="demoBanner"
          t={t}
          components={[
            <Link
              key="0"
              href="https://meetings.hubspot.com/justin-lessard-wajcer"
              underline="none"
              target="_blank"
            />,
          ]}
          ns="pageLayout"
        />
      </Text.P2Semibold>
      <TourButton />
    </Container.DemoMessage>
  );
}
