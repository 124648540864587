import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

// styles
import * as Styled from 'components/pageECoach/_styles/ChatStatusProgress.style';

interface RouteParams {
  id?: string;
}

interface Step {
  created_at: string;
  user_inputs?: Array<{ message: string }>;
  outputs?: Array<{
    output_type: number;
    message?: string;
    rag?: {
      summary: string;
      documents: any[];
    };
    [key: string]: any;
  }>;
}

interface ChatStatusProgressProps {
  steps?: Step[];
}

export default function ChatStatusProgress({
  steps,
}: ChatStatusProgressProps): React.ReactElement {
  const { t } = useTranslation('pageEcoach');
  const location = useLocation();
  const params = useParams<RouteParams>();
  const { id } = params;

  const searchParams = new URLSearchParams(location.search);

  const conversationIsActive =
    searchParams.get('new-conversation') !== null || id;

  if (!conversationIsActive) return <></>;

  const lastStep = steps.length > 0 ? steps[steps.length - 1] : null;
  const lastOutput = lastStep?.outputs
    ? lastStep.outputs[lastStep.outputs.length - 1]
    : null;

  const progress = lastOutput?.output_type || 0;

  // Use resolution status instead of progress, once backend returns live resolution status updates
  const getStatus = () => {
    if (progress === 0) return 'draft'; // CHALLENGE_VERIFICATION_QUESTION
    if (progress === 1) return 'draft'; // CHALLENGE_DESCRIPTION
    if (progress === 2) return 'draft'; // CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION
    if (progress === 3) return 'draft'; // CHALLENGE_VERIFICATION_CONFIRMATION_DECLINE_FOLLOW_UP
    if (progress === 4) return 'draft'; // CHALLENGE_VERIFICATION_SUGGESTION_TRANSITION
    if (progress === 15) return 'draft'; // CONVERSATION_PLAN_ABOUT
    if (progress === 16) return 'draft'; // CONVERSATION_PREP_WITH_WHO
    if (progress === 17) return 'draft'; // CONVERSATION_PREP_GOAL
    if (progress === 18) return 'draft'; // CONVERSATION_PREP_WORRIED_BY
    if (progress === 22) return 'resolved'; // SESSION_RESOLVED
    if (progress === 23) return 'resolved'; // SESSION_SKIPPED
    return 'active';
  };

  const status = getStatus();

  return (
    <Styled.ProgressContainer>
      <Styled.InnerContainer className="onboarding-tour-step-2 onboarding-tour-step-5">
        <Styled.Progress current={status === 'draft'}>
          {t('ChatStatusProgress.Draft')}
        </Styled.Progress>
        <Styled.Dot />
        <Styled.Progress current={status === 'active'}>
          {t('ChatStatusProgress.In progress')}
        </Styled.Progress>
        <Styled.Dot />
        <Styled.Progress current={['resolved', 'skipped'].includes(status)}>
          {t('ChatStatusProgress.Resolved')}
        </Styled.Progress>
      </Styled.InnerContainer>
    </Styled.ProgressContainer>
  );
}
