import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import EyeImg from 'assets/eye.png';
import voicelessImg from 'assets/voiceless.png';

// styles
import * as StyledCard from 'components/pageOnboarding/_styles/Cards.style';
import * as Styled from 'components/pageOnboarding/_styles/ConfidentialityCard.style';
import * as Text from 'components/_styles/Text.style';

function ConfidentialityCard() {
  const { t } = useTranslation('pageOnboarding');

  return (
    <StyledCard.Card>
      <Text.H5Bold>{t('privacyForm.Privacy disclaimer')}</Text.H5Bold>
      <Styled.AboutDescription>
        <Text.P2>
          {t(
            'privacyForm.By using Nurau, you agree that all information provided will be anonymized and processed for the sole purpose of improving our service.'
          )}
        </Text.P2>
        <Text.P2>
          {t(
            "privacyForm.While we take extensive measures to protect your privacy and ensure the confidentiality of your interactions, it's essential to understand that the information you share will be used exclusively to enhance the user experience and contribute to our ongoing research and development efforts."
          )}
        </Text.P2>
        <Text.P2>
          {t(
            'privacyForm.Your trust is our priority, and we are committed to maintaining the highest standards of security and privacy.'
          )}
        </Text.P2>
        <Text.P2>
          {t(
            'privacyForm.For more details on our privacy practices, please contact us at support@nurau.com'
          )}
        </Text.P2>
      </Styled.AboutDescription>
      <Styled.ListSection>
        <Styled.ListRow>
          <Styled.ListIcon src={EyeImg} />
          <Styled.ListDescriptionSection>
            <Text.P1Semibold>
              {t('privacyForm.Who can see your data')}
            </Text.P1Semibold>
            <Styled.DescriptionText>
              {t(
                'privacyForm.Your conversations with Nurau are confidential and are not shared with your manager or company.'
              )}
            </Styled.DescriptionText>
          </Styled.ListDescriptionSection>
        </Styled.ListRow>
        <Styled.ListRow>
          <Styled.ListIcon src={voicelessImg} />
          <Styled.ListDescriptionSection>
            <Text.P1Semibold>
              {t('privacyForm.How your data is used')}
            </Text.P1Semibold>
            <Styled.DescriptionText>
              {t(
                'privacyForm.Your data will be analyzed by non-humans for improving your experience on the app.'
              )}
            </Styled.DescriptionText>
          </Styled.ListDescriptionSection>
        </Styled.ListRow>
      </Styled.ListSection>
    </StyledCard.Card>
  );
}

export default ConfidentialityCard;
