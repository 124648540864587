import React from 'react';
import { Trans } from 'react-i18next';

// components
import StyledContent from 'components/productTours/StyledContent';

type TFunction = (key: string) => string;

interface TourContent {
  [key: string]: (t: TFunction) => React.ReactNode;
}

const onboardingTourContent: TourContent = {
  step1: (t) => (
    <StyledContent
      header={t('onboardingTour.slide1.header')}
      content={[
        t('onboardingTour.slide1.contentA'),
        <Trans
          key="slide1.contentB"
          i18nKey="onboardingTour.slide1.contentB"
          components={{ strong: <strong /> }}
          ns="productTour"
        />,
      ]}
    />
  ),

  step2: (t) => (
    <StyledContent
      header={t('onboardingTour.slide2.header')}
      content={[
        <Trans
          key="slide2.content"
          i18nKey="onboardingTour.slide2.content"
          components={{ strong: <strong /> }}
          ns="productTour"
        />,
      ]}
    />
  ),

  step3: (t) => (
    <StyledContent
      header={t('onboardingTour.slide3.header')}
      content={[t('onboardingTour.slide3.content')]}
    />
  ),

  step4: (t) => (
    <StyledContent
      header={t('onboardingTour.slide4.header')}
      content={[
        t('onboardingTour.slide4.contentA'),
        t('onboardingTour.slide4.contentB'),
      ]}
    />
  ),

  step5: (t) => (
    <StyledContent
      header={t('onboardingTour.slide5.header')}
      content={[
        <Trans
          key="slide5.content"
          i18nKey="onboardingTour.slide5.content"
          components={{ strong: <strong /> }}
          ns="productTour"
        />,
      ]}
    />
  ),

  step6: (t) => (
    <StyledContent
      header={t('onboardingTour.slide6.header')}
      content={[
        <Trans
          key="slide6.content"
          i18nKey="onboardingTour.slide6.content"
          components={{ strong: <strong /> }}
          ns="productTour"
        />,
      ]}
    />
  ),

  step7: (t) => (
    <StyledContent
      header={t('onboardingTour.slide7.header')}
      content={[
        t('onboardingTour.slide7.contentA'),
        <Trans
          key="slide7.contentB"
          i18nKey="onboardingTour.slide7.contentB"
          components={{ strong: <strong /> }}
          ns="productTour"
        />,
        t('onboardingTour.slide7.contentC'),
      ]}
    />
  ),

  step8: (t) => (
    <StyledContent
      header={t('onboardingTour.slide8.header')}
      hasButton={true}
    />
  ),
};

export default onboardingTourContent;
