import React from 'react';

// assets
import { Arrow } from 'untitledui-js';

// styles
import * as Styled from 'components/pageOnboarding/_styles/ButtonBackAndProgressIndicator.style';

interface ButtonBackAndProgressIndicatorProps {
  currentSlide: number;
  prevSlide: () => void;
  totalSlides: number;
}

function ButtonBackAndProgressIndicator({
  currentSlide,
  prevSlide,
  totalSlides,
}: ButtonBackAndProgressIndicatorProps): JSX.Element {
  return (
    <Styled.Section>
      {currentSlide === 0 ? (
        <div></div>
      ) : (
        <Arrow.ArrowLeft
          onClick={prevSlide}
          size={28}
          style={{ cursor: 'pointer' }}
        />
      )}

      <Styled.ProgressIndicator>
        {Array.from({ length: totalSlides }, (_, index) => (
          <Styled.ProgressDot
            key={index}
            currentSlide={currentSlide === index}
          />
        ))}
      </Styled.ProgressIndicator>
    </Styled.Section>
  );
}

export default ButtonBackAndProgressIndicator;
